<script>
export default {
  mounted() {
    this.fetchFYs();
    this.fetchLocations();
  },
  data() {
    return {
      selectPeriod: false,
      pmyMonth: null,
      pmyYear: null,
      pmyDate: null,
      useCurrent: false,
      location: null,
      locations: [{ value: null, text: "Select location" }],
      stage:null,
      subCategories:[
        {
          value:0,
          text:"All"
        },
        {
        value:1,
        text:"Corper"
        },
        {
          value:2,
          text:"International Staff"
        },
        {
          value:3,
          text:"National Staff"
        },
        {
          value:4,
          text:"Regional"
        },
        {
          value:5,
          text:"Interns"
        },
        {
          value:6,
          text:"Consultant"
        },
        {
          value:7,
          text:"Volunteer"
        },
        {
          value:8,
          text:"Incentive Workers"
        },
        {
          value:9,
          text:"Casual Workers"
        },
      ],
      subcategory:null,
      stages: [],
      period:null
    };
  },
  methods: {
    fetchFYs() {
      this.apiGet(
        `${this.ROUTES.selfAssessment}/get-all-fys`,
        "Error Fetching FYs"
      ).then((res) => {
        if (res.data) {
          const fys = res.data;
          //console.log(fys)
          fys.map(fy=>{
            this.fys.push(fy.sam_year)
          });
        }
      });
    },

    fetchLocations() {
      this.apiGet(this.ROUTES.location, "Get Locations Error").then((res) => {
        this.locations = [
          { value: null, text: "Select location" },
          { value: 0, text: "All locations" },
        ];
        const { data } = res;
        data.forEach((location) => {
          this.locations.push({
            value: location.location_id,
            text: `${location.l_t6_code} (${location.location_name})`,
          });
        });
      });
    },
    async generate() {
      const selectedDate = new Date(this.period);
      const month = selectedDate.getMonth()+1;
      const year = selectedDate.getFullYear();
      await this.$router.push({
        name: "master-report-list",
        params: {
          month: month,
          year: year,
          location: this.location,
          subcategory: this.subcategory,
        },
      });
/*
      console.log("Period: "+this.period)
      console.log("Month: "+month)
      console.log("Year: "+year)
      console.log("Subcategory: "+this.subcategory)
      console.log("Location: "+this.location)
      data = {
        location_id:parseInt(this.location),
        month: month.toString(),
        year: year.toString(),
        sub_category:parseInt(this.subcategory)
      };
      const url = `${this.ROUTES.masterList}`;
      this.apiPost(url, data, "Generate Master List Report").then((res) => {
        if (res) {
          console.log(res.data);
        }
      });*/
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="media">
        <div class="media-body overflow-hidden">
          <h4 class="mb-0">Master List Report</h4>
        </div>
        <div class="text-secondary"></div>
      </div>
    </div>
    <div class="card-body border-top py-2">
      <div
        class="d-flex align-items-center text-success d-inline-flex"
        style="cursor: pointer"
        @click="selectPeriod = true"
      >
        <span class="mr-2">Generate</span>
        <i class="ri-arrow-right-s-line"></i>
      </div>
    </div>
    <b-modal
      v-model="selectPeriod"
      title="Generate Master List Report"
      hide-footer
      centered
      title-class="font-18"
      size="md"
      no-close-on-backdrop
      no-close-on-esc
    >
      <form @submit.prevent="generate">
        <div class="form-group">
          <label for="">Period</label>
          <input v-model="period" type="month" class="form-control" />
        </div>
        <div class="form-group">
          <label for="">Select Location</label>
          <b-form-select v-model="location" :options="locations" />
        </div>
        <div class="form-group">
          <label for="">Sub-category</label>
          <b-form-select v-model="subcategory" :options="subCategories" />
        </div>
        <b-button
          v-if="!submitting"
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submit
        </b-button>
        <b-button
          v-else
          disabled
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submitting...
        </b-button>
      </form>
    </b-modal>
  </div>
</template>
